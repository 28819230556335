import * as PciFieldTypeEnum from "./PciFieldTypeEnum";
import AbstractPciField from "./AbstractPciField";
import {getIframe} from "../utils/IFrameUtils";
import {cvcIsValid, getMaxLength} from "../utils/CvcUtils";

class SecurityCodeField extends AbstractPciField {
    constructor(sessionId, inputDom) {
        super(PciFieldTypeEnum.SECURITY_CODE, sessionId, inputDom);
        this._cardNumberField = null;
    }

    onInput(e) {
        super.onInput(e);
    }

    updateValidity() {
        this._previousValidity = this._currentValidity;

        const cardNumberField = this._getCardNumberField();
        this._currentValidity  = cvcIsValid(this.currentValue, cardNumberField.currentCardType);

        this.checkValidityHasChanged();
    }

    _getCardNumberField() {
        if (!this._cardNumberField) {
            const cardNumber = this._getCardNumberFrame();
            this._cardNumberField = cardNumber.getPciField();
        }

        return this._cardNumberField;
    }

    _getCardNumberFrame() {
        return getIframe(this.sessionId, PciFieldTypeEnum.CARD_NUMBER);
    }

    setMaxLength() {
        const cardNumberField = this._getCardNumberField();
        let cardValue = cardNumberField.currentValue;
        let maxLength = getMaxLength(cardValue);

        if (maxLength) {
            this._inputDom.maxLength = maxLength;
            this._updateValueAccordingMaxLength(maxLength);
        }
    }

    _updateValueAccordingMaxLength(maxLength) {
        let newValue = this._inputDom.value.substring(0, maxLength);
        super._updateValue(newValue);
        this._inputDom.value = newValue;
    }
}

export default SecurityCodeField