const setStyleToField = (data, inputDom) => {
    if (data.placeholder) {
        inputDom.placeholder = data.placeholder;
    }
    if(data.maxLength) {
        inputDom.maxLength = data.maxLength;
    }
    if (data.fontSize) {
        inputDom.style["font-size"] = data.fontSize;
    }
    if (data.fontFamily) {
        inputDom.style["font-family"] = data.fontFamily;
    }
    if (data.color) {
        inputDom.style["color"] = data.color;
    }
    if (data.fontWeight) {
        inputDom.style["font-weight"] = data.fontWeight;
    }
    if (data.padding) {
        inputDom.style["padding"] = data.padding;
    }
    if (data.tooltip) {
        inputDom.title = data.tooltip;
    }
};

const setMaxLength = (maxLength,inputDom) => {
    inputDom.maxLength = maxLength;
};

export {setStyleToField, setMaxLength}