import * as MessageTypeEnum from "../MessageTypeEnum";

class AbstractEventMessage {
    constructor(type, field, payload) {
        this.type = type;
        this.field = field;
        this.payload = payload;
    }
}

class OnFocus extends AbstractEventMessage {
    constructor(field) {
        super(MessageTypeEnum.FOCUS, field);
    }
}

class OnBlur extends AbstractEventMessage {
    constructor(field) {
        super(MessageTypeEnum.BLUR, field);
    }
}

class OnEmpty extends AbstractEventMessage {
    constructor(field) {
        super(MessageTypeEnum.EMPTY, field);
    }
}

class OnNotEmpty extends AbstractEventMessage {
    constructor(field) {
        super(MessageTypeEnum.NOT_EMPTY, field);
    }
}

class OnSubmit extends AbstractEventMessage {
    constructor(field) {
        super(MessageTypeEnum.SUBMIT, field);
    }
}

class OnPageReady extends AbstractEventMessage {
    constructor(field) {
        super(MessageTypeEnum.PAGE_READY, field);
    }
}

class OnValidityChange extends AbstractEventMessage {
    constructor(field, payload) {
        super(MessageTypeEnum.VALIDITY_CHANGE, field, payload);
    }
}

class OnCardTypeChange extends AbstractEventMessage {
    constructor(field, payload) {
        super(MessageTypeEnum.CARD_TYPE_CHANGE, field, payload);
    }
}

class OnPaymentCall extends AbstractEventMessage {
    constructor(field, payload) {
        super(MessageTypeEnum.PAYMENT_STATUS, field, payload);
    }
}

export {OnFocus, OnBlur, OnEmpty, OnNotEmpty, OnSubmit, OnPageReady, OnValidityChange, OnCardTypeChange, OnPaymentCall}


