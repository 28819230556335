const SESSION_ID = 'sessionId';
const TYPE = 'type';
const PLACE_HOLDER = 'placeholder';
const FONT_SIZE = 'fontSize';
const FONT_FAMILY = 'fontFamily';
const COLOR = 'color';
const FONT_WEIGHT = 'fontWeight';
const MAXLENGTH = 'maxLength';
const PADDING = 'padding';
const TOOLTIP = 'tooltip';

export {SESSION_ID, TYPE, PLACE_HOLDER, FONT_SIZE, FONT_FAMILY, COLOR, FONT_WEIGHT, MAXLENGTH, PADDING, TOOLTIP}


