
const getIframe = (sessionId, type) => {
    let securityCodeFrame;

    for (let i = 0; i < parent.window.frames.length; i++) {
        let location = parent.window.frames[i].location;
        let sameOrigin;
        try {
            sameOrigin = location.host === window.location.host;
        }catch(e) {
            // Cross origin error received meaning the frame is not on the same domain.
            sameOrigin = false;
        }

        if(sameOrigin) {
            let isSecurityCode = location.search.indexOf('type=' + type) > 0;
            let validSessionId = location.search.indexOf("sessionId=" + sessionId) > 0;

            if (isSecurityCode && validSessionId) {
                securityCodeFrame = parent.window.frames[i];
                break;
            }
        }
    }

    return securityCodeFrame;
}

export {getIframe}