import creditCardType from 'credit-card-type'
import {getCardTypeExternalFormat, getCreditCardData} from './CardTypeUtils'

const cvcIsValid = (cvcValue, cardType) => {
    let cardTypeExternalFormat = getCardTypeExternalFormat(cardType);
    let cardData = creditCardType.getTypeInfo(cardTypeExternalFormat);
    let codeSize = cardData ? cardData.code.size : cardData;
    let pattern = /^\d+$/;

    let isNumber = pattern.test(cvcValue);
    let hasValidCodeSize = cvcValue.length === codeSize;

    return isNumber && hasValidCodeSize;
};

const getMaxLength = (cardValue) => {
    let cardData = getCreditCardData(cardValue);
    let maxLength;

    if (cardData) {
        maxLength = cardData.code.size;
    }

    return maxLength;
};

export {cvcIsValid, getMaxLength}