// Url search param polyfill
import URLSearchParams from '@ungap/url-search-params';
import {setStyleToField} from './commons/utils/StyleUtils';
// Less file
import '../less/field.less';
import * as UrlParams from './commons/UrlParams'
import * as PciFieldTypeEnum from "./commons/model/PciFieldTypeEnum";
import SecurityCodeField from "./commons/model/SecurityCodeField";
import CardNumberField from "./commons/model/CardNumberField";

let urlSearchParams = new URLSearchParams(window.location.search);

let sessionId = urlSearchParams.get(UrlParams.SESSION_ID);
let type = urlSearchParams.get(UrlParams.TYPE);
let style = {};
style.placeholder = urlSearchParams.get(UrlParams.PLACE_HOLDER);
style.fontSize = urlSearchParams.get(UrlParams.FONT_SIZE);
style.fontFamily = urlSearchParams.get(UrlParams.FONT_FAMILY);
style.color = urlSearchParams.get(UrlParams.COLOR);
style.fontWeight = urlSearchParams.get(UrlParams.FONT_WEIGHT);
style.maxLength = urlSearchParams.get(UrlParams.MAXLENGTH);
style.padding = urlSearchParams.get(UrlParams.PADDING);
style.tooltip = urlSearchParams.get(UrlParams.TOOLTIP);

let inputDom = document.getElementById('field');

setStyleToField(style, inputDom);

let pciField;

if (type === PciFieldTypeEnum.CARD_NUMBER) {
    pciField = new CardNumberField(sessionId, inputDom);

} else if (type === PciFieldTypeEnum.SECURITY_CODE) {
    pciField = new SecurityCodeField(sessionId, inputDom);
}
inputDom.onblur = () => {
    pciField.onBlur();
};

inputDom.onfocus = () => {
    pciField.onFocus();
};

inputDom.oninput = (e) => {
    pciField.onInput(e);
};

inputDom.onkeypress = (e) => {
    pciField.onKeyPress(e);
};

window.addEventListener('message', function (event) {
    if (event.data.type === 'doPayment') {
        let data = event.data.payload;
        pciField.doPayment(data);

    } else if (event.data.type === "style") {
        setStyleToField(event.data.payload, inputDom);
    }
});

window.getPciField = () => {
    return pciField;
}

pciField.onPageReady();