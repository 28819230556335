import AbstractPciField from "./AbstractPciField";
import {postMessageToParent} from '../utils/MessageUtils'
import {getIframe} from '../utils/IFrameUtils'
import {getCardType, cardIsValid} from '../utils/CardTypeUtils';
import * as PciFieldTypeEnum from "./PciFieldTypeEnum";
import * as PaymentStatusEnum from "../messages/toParent/PaymentStatus/PaymentStatusEnum";
import {OnCardTypeChange, OnPaymentCall} from "../messages/toParent/EventMessages";
import {getGaps, getMaxLength} from '../utils/CardTypeUtils'

const NUMBER_FIRST_DIGITS = 6;

class CardNumberField extends AbstractPciField {

    constructor(sessionId, inputDom) {
        super(PciFieldTypeEnum.CARD_NUMBER, sessionId, inputDom);
        this._previousCardType = null;
        this._currentCardType = null;
        this._previousFirstDigits = null;
        this._currentFirstDigits = null;
        this._securityCodeField = null;
    }

    onInput(e) {
        super.onInput(e);
        this._checkCardTypeChange();
        this._formatWithGaps(e);
    }

    doPayment(data) {
        const cardNumber = this.currentValue;
        const securityCode = this._getSecurityCodeField().currentValue;

        if (!data.cardHolderName || !data.validity || !data.validity.month || !data.validity.year || !cardNumber || !this.currentValidity || !securityCode) {
            this._sendPaymentEvent(PaymentStatusEnum.FAILURE);
        } else {
            this._submitForm(data.cardHolderName, data.cardHolderFirstName, cardNumber, securityCode, data.validity);
        }
    }

    _getSecurityCodeField() {
        if (!this._securityCodeField) {
            let securityCodeFrame = this._getSecurityCodeFrame();
            this._securityCodeField = securityCodeFrame.getPciField();
        }

        return this._securityCodeField;
    }

    _submitForm(cardHolderName, cardHolderFirstName, cardNumber, securityCode, validity) {

        let url = "onepay-ui-core/api/rest/page/payment";

        let data = {
            sessionId: this.sessionId,
            paymentHolder: {
                name: cardHolderName,
                firstname: cardHolderFirstName
            },
            cardInformation: {
                cardNumber: cardNumber,
                cvc: securityCode,
                cardType: this._currentCardType,
                expiryDate: {
                    month: validity.month,
                    year: validity.year
                }
            }
        };

        this.callPaymentWebservice(url, data);
    }

    /**
     * Trigger an HTTP POST request to the payment service.
     *
     * @param url the URL of the payment service.
     * @param data the data to be posted.
     */
    callPaymentWebservice(url, data) {

        class PaymentException {

            constructor(message) {
                this.message = message;
                this.name = 'PaymentException';
            }

            get message() {
                return this.message;
            }

            set message(message) {
                this.message = message;
            }

            get name() {
                return this.name;
            }

            set name(name) {
                this.name = name;
            }
        }

        let reqHeaders = new Headers();
        reqHeaders.append("Content-type", "application/json");
        reqHeaders.append("Accept", "application/json");

        window.fetch(url, {method: 'POST', headers: reqHeaders, body: JSON.stringify(data)})
            .then(
                (res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new PaymentException('Request failed');
                    }
                }
            ).then(
            (json) => {
                if (json.status === 'Success') {
                    this._sendPaymentEvent(PaymentStatusEnum.SUCCESS);
                } else {
                    this._sendPaymentEvent(PaymentStatusEnum.FAILURE);
                }
            }
        ).catch(
            () => {
                this._sendPaymentEvent(PaymentStatusEnum.FAILURE);
            }
        );
    }

    _sendPaymentEvent(status) {
        const event = new OnPaymentCall(this._currentCardType, status);
        postMessageToParent(event);
    }

    _updateValue(newValue) {
        newValue = newValue.replace(/\s/g, '');

        super._updateValue(newValue);

        this._previousCardType = this._currentCardType;
        this._currentCardType = getCardType(this.currentValue);

        this._previousFirstDigits = this._currentFirstDigits;
        this._currentFirstDigits = this._getFirstDigitsFromCardNumber();
    }

    _checkCardTypeChange() {
        let cardTypeHasChanged = this._previousCardType !== this._currentCardType;

        if (cardTypeHasChanged) {
            // check if the validity of the security code has changed with the new type of card
            const securityCodeField = this._getSecurityCodeField();
            securityCodeField.setMaxLength();
            securityCodeField.updateValidity();
        }

        let event = new OnCardTypeChange(
            this._inputType,
            {
                cardType: this._currentCardType,
                firstDigits: this._currentFirstDigits
            }
        );

        if (event) {
            postMessageToParent(event);
        }
    }

    _formatWithGaps(e) {
        let cardValue = this._currentValue;
        let gaps = getGaps(this._currentCardType) ? getGaps(this._currentCardType).reverse() : getGaps(this._currentCardType);

        if (gaps) {
            gaps.forEach(function (gap) {
                if (gap < cardValue.length) {
                    cardValue = cardValue.substring(0, gap) + " " + cardValue.substring(gap, cardValue.length);
                }
            });
            e.target.value = cardValue;
        }
    }

    setMaxLength() {
        let cardValue = this.currentValue;
        let maxLength = getMaxLength(cardValue);
        if (maxLength) {
            this._inputDom.maxLength = maxLength;
            this._updateValueAccordingMaxLength(maxLength);
        }
    };

    _updateValueAccordingMaxLength(maxLength) {
        let numberGaps = getGaps(this.currentCardType).length;
        let newValue = this._inputDom.value.substring(0, maxLength - numberGaps);
        super._updateValue(newValue);
        this._inputDom.value = newValue;
    };

    // return the first 6 digits + xxx;
    _getFirstDigitsFromCardNumber() {
        return this.currentValue.substring(0, NUMBER_FIRST_DIGITS) + this.currentValue.substring(NUMBER_FIRST_DIGITS, this.currentValue.length).replace(/[0-9]/g, "x");
    }

    _getSecurityCodeFrame() {
        return getIframe(this.sessionId, PciFieldTypeEnum.SECURITY_CODE);
    }

    updateValidity() {
        this._previousValidity = this._currentValidity;
        this._currentValidity  = cardIsValid(this.currentValue);

        this.checkValidityHasChanged();
    }

    get currentCardType() {
        return this._currentCardType;
    }
}

export default CardNumberField