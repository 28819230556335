const mapCreditCardType = new Map([
    ["visa", "VISA"],
    ["american-express", "AMEX"],
    ["mastercard", "MASTERCARD"],
    ['diners-club', 'DINERS'],
    ['discover', 'DISCOVER'],
    ['jcb', 'JCB'],
    ['unionpay', 'CUP'],
    ['maestro', 'MAESTRO'],
    ['hiper', "HIPER"],
    ['hipercard', "HIPERCARD"],
    ['mir', "MIR"],
    ['elo', "ELO"],

]);

export default mapCreditCardType