const FOCUS = 'focus';
const BLUR = 'blur';
const EMPTY = 'empty';
const NOT_EMPTY = 'notEmpty';
const CARD_TYPE_CHANGE = 'cardTypeChange';
const PAGE_READY = 'pageReady';
const SUBMIT = 'submit';
const VALIDITY_CHANGE = 'validityChange';
const PAYMENT_STATUS = 'paymentStatus';

export {FOCUS, BLUR, EMPTY, NOT_EMPTY, CARD_TYPE_CHANGE, PAGE_READY, SUBMIT, VALIDITY_CHANGE, PAYMENT_STATUS}