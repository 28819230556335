import {postMessageToParent} from '../utils/MessageUtils'
import {
    OnBlur, OnFocus, OnEmpty, OnNotEmpty, OnPageReady, OnSubmit,
    OnValidityChange
} from "../messages/toParent/EventMessages";

class AbstractPciField {

    constructor(inputType, sessionId, inputDom) {
        this._currentValue = '';
        this._previousValue = '';
        this._currentValidity = false;
        this._previousValidity = false;
        this._inputType = inputType;
        this._sessionId = sessionId;
        this._inputDom = inputDom;
    }

    get inputType() {
        return this._inputType;
    }

    onFocus() {
        const event = new OnFocus(this._inputType);
        postMessageToParent(event);
    }

    onBlur() {
        const event = new OnBlur(this._inputType);
        postMessageToParent(event);
    }

    onInput(e) {
        this._removeNotNumber(e);
        this._updateValue(e.target.value);
        this._emptyCheck();
        this.setMaxLength();
        this.updateValidity();
    }

    onKeyPress(e) {
        if (e.code === "Enter") {
            const event = new OnSubmit(this._inputType);
            postMessageToParent(event);
        }
    }

    onPageReady() {
        const event = new OnPageReady(this._inputType);
        postMessageToParent(event);
    }

    _removeNotNumber(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
    }

    _updateValue(newValue) {
        this._previousValue = this.currentValue;
        this._currentValue = newValue;
    }

    _emptyCheck() {
        if (this.currentValue === "" && this.previousValue !== "") {
            // From filled to void
            const event = new OnEmpty(this.inputType);
            postMessageToParent(event);
        } else if (this.currentValue !== "" && this.previousValue === "") {
            // From void to filled
            const event = new OnNotEmpty(this.inputType);
            postMessageToParent(event);
        }
    }

    checkValidityHasChanged() {
        let hasValidityChanged = this.previousValidity !== this.currentValidity;

        if (hasValidityChanged) {
            const event = new OnValidityChange(this.inputType, this.currentValidity);
            postMessageToParent(event);
        }
        return true;
    }

    // Override me
    updateValidity () {}
    setMaxLength() {}

    get currentValidity() {
        return this._currentValidity;
    }

    get previousValidity() {
        return this._previousValidity;
    }

    get previousValue() {
        return this._previousValue;
    }

    get currentValue() {
        return this._currentValue;
    }

    get sessionId() {
        return this._sessionId;
    }

    set sessionId(value) {
        this._sessionId = value;
    }
}

export default AbstractPciField