import creditCardType from 'credit-card-type'
import luhn from 'luhn'
import mapCreditCardType from '../mapping/CardTypeMapping'

const getCardType = (cardValue) => {
    cardValue = cardValue.replace(/\s/g, '');
    let cardData = getCreditCardData(cardValue);

    return cardData ? mapCreditCardType.get(cardData.type) : null;
};

const getCardTypeExternalFormat = (cardType) => {
    let cardTypeExternalFormat;

    for (let [externalFormat, internalFormat] of mapCreditCardType) {
        if (internalFormat === cardType) {
            cardTypeExternalFormat = externalFormat;
            break;
        }
    }

    return cardTypeExternalFormat;
};

const getCreditCardData = (cardValue) => {
    let creditCardData = null;
    let allCardTypesAccordingValue = creditCardType(cardValue);

    if (allCardTypesAccordingValue.length === 1) {
        creditCardData = allCardTypesAccordingValue[0];
    }

    return creditCardData;
};

const cardIsValid = (cardValue) => {
    let validCard = false;
    let cardData = getCreditCardData(cardValue);

    if (cardData) {
        let authorizedLengths = cardData.lengths;
        let hasAutorizedLength = authorizedLengths.indexOf(cardValue.length) > -1;
        let hasValidLuhn = luhn.validate(cardValue);

        if (hasAutorizedLength && hasValidLuhn) {
            validCard = true;
        }
    }
    return validCard;
};

const getGaps = (cardType) => {
    let cardTypeExternalFormat = getCardTypeExternalFormat(cardType);
    let cardData = creditCardType.getTypeInfo(cardTypeExternalFormat);

    return cardData ? cardData.gaps : cardData;
};

const getMaxLength = (cardValue) => {
    let cardData = getCreditCardData(cardValue);
    let maxLength;

    if (cardData) {
        let lengthsArray = cardData.lengths;
        let maxLengthInArray = lengthsArray[lengthsArray.length - 1];
        let numberOfSpaces = cardData.gaps.length;

        maxLength = maxLengthInArray + numberOfSpaces;
    }

    return maxLength;
};

export {getCardType, cardIsValid, getCardTypeExternalFormat, getGaps, getMaxLength, getCreditCardData}